import Link from 'next/link';

import { MainLayout } from '@/layouts/main-layout';

import { PageSeo } from '@/shared/lib/seo';

const HomePage = () => {
  return (
    <>
      <PageSeo title="Главная страница" />
      <MainLayout>
        <div className="container">
          <div className=" mt-4">Главная страница</div>
          <h2 className="mt-4 text-center text-2xl font-bold">Источники бесперебойного питания</h2>
          <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-4">
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">Промышленные ИБП</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">Медицинские ИБП</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">Онлайн Ибп</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">Литейно-интерактивные ИБП</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">ИБП для аварийных систем</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">ИБП для ЦОД и серверной</div>
            </Link>
            <Link href="/catalog" className="my-auto flex h-32 w-full border text-center text-xl hover:shadow-lg">
              <div className="m-auto">Литиевые ИБП</div>
            </Link>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default HomePage;
